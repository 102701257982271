import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import apiHelper from '../../Commen/ApiHelper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function createData(name, count, phone, city, id, role) {
  return {
    name,
    count,
    phone,
    city,
    id,
    role,
    history: [

    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="center">{row.phone}</TableCell>
        <TableCell align="center">{row.count}</TableCell>
        <TableCell align="center">{row.city}</TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Orders Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="center">Qty</TableCell>
                    <TableCell align="center">Delivery Due</TableCell>
                    <TableCell align="center">Delivery Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.history?.map((historyRow) => (
                    <TableRow key={historyRow.name}>
                      <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>₹ {historyRow.price}</TableCell>
                      <TableCell align="center">{historyRow.qty}</TableCell>
                      <TableCell align="center">
                        {historyRow.deliveryDue.split("T")[0]}
                      </TableCell>
                      <TableCell align="center">
                        <span className={historyRow.deliveryStatus === 1 ? "btn btn-success" : historyRow.deliveryStatus === 2 ? "btn btn-danger" : "btn btn-warning"}>

                          {historyRow.deliveryStatus === 1 ? "Delivered" : historyRow.deliveryStatus === 2 ? "Cancelled" : "Pending"}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function AdminDashboard({ Auth }) {

  const [distributorTab, setDistributorTab] = React.useState(0);
  const [customerTab, setCustomerTab] = React.useState(0);
  const [orders, setorders] = React.useState([]);
  const [Distributers, setDistributers] = React.useState([]);
  const [totalUSers, settotalUSers] = React.useState([]);


  async function getUsers(params) {
    try {
      const result = await apiHelper.listCustomers()
      settotalUSers(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function getDistributers() {
    try {
      const result = await apiHelper.listDistributers()
      setDistributers(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }
  const [userData, setUserData] = React.useState([]);

  useEffect(() => {
    async function getUserData() {
      try {
        const result = await apiHelper.allOrderList();
        const orders = result.data.data;
        setorders(orders)
        const userDataMap = new Map();
        await Promise.all(
          orders.map(async (order) => {
            if (!order?.orderedBy?._id) return;
            const userId = order.orderedBy._id;
            if (!userDataMap.has(userId)) {
              const userOrders = await apiHelper.listOrderByUser(userId);
              console.log(userOrders.data.data)
              userDataMap.set(userId, {
                name: order.orderedBy.fullName,
                phone: order.orderedBy.phone || "-",
                count: userOrders?.data?.data?.filter(item => (item.orderStatus === 1 || item.deliveryStatus === 0) && item.deliveryStatus !== 1)?.length,
                city: order.orderedBy.city || "-",
                role: order.orderedBy.role || "-",
                history: userOrders.data.data.map((userOrder) => ({
                  name: userOrder.productId?.name,
                  price: userOrder.productId?.price,
                  qty: userOrder.qty,
                  deliveryStatus: userOrder.deliveryStatus,
                  deliveryDue: userOrder.deliveryDue
                })),
              });
            }
          })
        );

        const uniqueData = Array.from(userDataMap.values());
        setUserData(uniqueData);
        const Dis_Data = uniqueData.filter(data => data.role === 1)
        setDistributorData(Dis_Data)
        const Cust_Data = uniqueData.filter(data => data.role === 2)
        setCustomerData(Cust_Data)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    getUserData();
  }, []);



  React.useEffect(() => {
    getUsers()
    getDistributers()
    // eslint-disable-next-line
  }, []);


  const data = [
    { name: 'Jan', Sales: 4000, Expenses: 2400 },
    { name: 'Feb', Sales: 3000, Expenses: 1398 },
    { name: 'Mar', Sales: 2000, Expenses: 9800 },
    { name: 'Apr', Sales: 2780, Expenses: 3908 },
    { name: 'May', Sales: 1890, Expenses: 4800 },
    { name: 'Jun', Sales: 2390, Expenses: 3800 },
  ];

  const filteredOrders = {
    distributors: {
      all: orders.filter(order => order?.orderedBy?.role === 1),
      pending: orders.filter(order => order.paymentStatus === 0 && order?.orderedBy?.role === 1),
      completed: orders.filter(order => order.paymentStatus === 1 && order?.orderedBy?.role === 1),
    },
    customer: {
      all: orders.filter(order => order?.orderedBy?.role === 2),
      pending: orders.filter(order => order.paymentStatus === 0 && order?.orderedBy?.role === 2),
      completed: orders.filter(order => order.paymentStatus === 1 && order?.orderedBy?.role === 2),
    },

  };

  const [distributorData, setDistributorData] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [peddingOrders, setpeddingOrders] = React.useState(0);
  const [totalIncome, settotalIncome] = React.useState(0);


  React.useEffect(() => {
    if (userData.length > 0) {
      const peddingOrders = filteredOrders?.distributors?.pending?.length
      const completedOrder = filteredOrders?.distributors?.completed;
      let income = 0
      for (let i = 0; i < completedOrder.length; i++) {
        income += completedOrder[i].totalAmount
      }
      settotalIncome(income)
      setpeddingOrders(peddingOrders)
    }
  }, [userData]);

  return <div className="Dashboard">
    <div className="container">
      <div className="row">
        <div className="col-6 col-lg-3 my-2">
          <div className="d-flex justify-content-center align-items-center shadow p-3">
            <div className="content">
              <div className="text-center">
                <PersonIcon className='fs-2' />
              </div>
              <div className="count my-2">
                <div className="h my-2">Total User</div>
                <div className="h1 text-center">{totalUSers?.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 my-2">
          <div className="d-flex justify-content-center align-items-center shadow p-3">
            <div className="content">
              <div className="text-center">
                <ManageAccountsIcon className='fs-2' />
              </div>
              <div className="count my-2">
                <div className="h my-2">Total Distributer</div>
                <div className="h1 text-center">{Distributers?.length}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 my-2">
          <div className="d-flex justify-content-center align-items-center shadow p-3">
            <div className="content">
              <div className="text-center">
                <ShoppingCartIcon className='fs-2' />
              </div>
              <div className="count my-2">
                <div className="h6 text-center">Total Order</div>
                <div className="h1 text-center">{peddingOrders}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 col-lg-3 my-2">
          <div className="d-flex justify-content-center align-items-center shadow p-3">
            <div className="content">
              <div className="text-center">
                <CurrencyRupeeIcon className='fs-2' />
              </div>
              <div className="count my-2">
                <div className="h6 text-center">Total Income</div>
                <div className="h1 text-center">{totalIncome}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container my-4">
      <div className="row">
        <div className="col-12 col-md-6">
          <div style={{ height: 400, width: "100%" }}>
            <ResponsiveContainer>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Sales" fill="blue" />
                <Bar dataKey="Expenses" fill="chocolate" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="h4 fw-bold">Distributors</div>
          <div className="Dis_list">
            <div className="Dis_Scroll border p-2 ps-3">
              <div className="row p-2">
                {
                  Distributers && Distributers.map((item) => {
                    return <div className="col-12 border p-2">
                      <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                        <div className="text-sm-truncate text-center" style={{ maxWidth: '30%' }}>{item?.fullName}</div>
                        <div className="text-sm-truncate text-center">{item?.phone}</div>
                        <div className="text-sm-truncate text-center" style={{ maxWidth: '40%' }}>{item?.city}</div>
                        {/* <div className="d-flex gap-2">
                                            <button onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm">Delete</button>
                                        </div> */}
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="order_room col-12 my-5">
          <div className="h4 fw-bold">Order Room</div>
          <div className="container border">
            <div className="h5 m-3 border-bottom p-2 pb-3">Distributors Orders</div>
            <div className="row">
              <div className="col-12">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={distributorTab}
                      onChange={(event, newValue) => setDistributorTab(newValue)}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="basic tabs example"
                    >
                      <Tab label="All Orders" {...a11yProps(0)} />
                      <Tab label="Pending Orders" {...a11yProps(1)} />
                      <Tab label="Completed Orders" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={distributorTab} index={0}>
                    <div className="row">
                      <div className="col-12">
                        <TableContainer component={Paper}>
                          <Table aria-label="collapsible table">
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>User Name</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Pending Orders</TableCell>
                                <TableCell align="center">City</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {distributorData?.map((row, index) => (
                                <Row key={index} row={row} />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>



                      </div>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={distributorTab} index={1}>
                    <div className="row">
                      {
                        filteredOrders?.distributors?.pending?.map((item) => {
                          return <div className="col-12 col-sm-6 col-md-4 my-2">
                            <div className="card shadow p-4">
                              <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                              <div className="h5">{item?.orderedBy?.fullName}</div>
                              <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                              <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                              <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                              <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={distributorTab} index={2}>
                    <div className="row">
                      {
                        filteredOrders?.distributors?.completed?.map((item) => {
                          return <div className="col-12 col-sm-6 col-md-4 my-2">
                            <div className="card shadow p-4">
                              <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                              <div className="h5">{item?.orderedBy?.fullName}</div>
                              <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                              <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                              <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                              <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </div>
          </div>

          <div className="container border">
            <div className="h5 m-3 border-bottom pb-3 p-2 ">Customer Orders</div>
            <div className="row">
              <div className="col-12">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={customerTab}
                      onChange={(event, newValue) => setCustomerTab(newValue)}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="basic tabs example"
                    >
                      <Tab label="All Orders" {...a11yProps(0)} />
                      <Tab label="Pending Orders" {...a11yProps(1)} />
                      <Tab label="Completed Orders" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={customerTab} index={0}>
                    <div className="row">
                      <div className="col-12">
                        <TableContainer component={Paper}>
                          <Table aria-label="collapsible table">
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>User Name</TableCell>
                                <TableCell align="center">Phone</TableCell>
                                <TableCell align="center">Pendind Orders</TableCell>
                                <TableCell align="center">City</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customerData?.map((row, index) => (
                                <Row key={index} row={row} />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={customerTab} index={1}>
                    <div className="row">
                      {
                        filteredOrders?.customer?.pending?.map((item) => {
                          return <div className="col-12 col-sm-6 col-md-4 my-2">
                            <div className="card shadow p-4">
                              <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                              <div className="h5">{item?.orderedBy?.fullName}</div>
                              <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                              <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                              <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                              <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </CustomTabPanel>

                  <CustomTabPanel value={customerTab} index={2}>
                    <div className="row">
                      {
                        filteredOrders?.customer?.completed?.map((item) => {
                          return <div className="col-12 col-sm-6 col-md-4 my-2">
                            <div className="card shadow p-4">
                              <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                              <div className="h5">{item?.orderedBy?.fullName}</div>
                              <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                              <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                              <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                              <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </CustomTabPanel>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
  </div>
}