import PersonIcon from '@mui/icons-material/Person';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import apiHelper from '../../Commen/ApiHelper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function createData(userName, role, phone, city, id, count, productDetails) {
    return {
        userName,
        role,
        phone,
        city,
        id,
        count,
        productDetails,
    };
}


function Row({ row, index }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">{row?.userName}</TableCell>
                <TableCell align="center">{row?.phone}</TableCell>
                <TableCell align="center">{row?.count}</TableCell>
                <TableCell align="center">{row?.city}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">Product Details</Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='fw-bold'>Product Name</TableCell>
                                        <TableCell className='fw-bold'>Price</TableCell>
                                        <TableCell className='fw-bold' align="center">Quantity</TableCell>
                                        <TableCell className='fw-bold' align="center">Total</TableCell>
                                        <TableCell className='fw-bold' align="center">Due Date</TableCell>
                                        <TableCell className='fw-bold' align="center">Payment Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.productDetails?.map((product) => (
                                        <TableRow key={product.productId}>
                                            <TableCell component="th" scope="row">{product.productName}</TableCell>
                                            <TableCell>{product.price}</TableCell>
                                            <TableCell align="center">{product.quantity}</TableCell>
                                            <TableCell align="center">{product.total}</TableCell>
                                            <TableCell align="center">{product.deliveryDue.split("T")[0]}</TableCell>
                                            <TableCell align="center">
                                                <span className={product.deliveryStatus === 2 ? "btn btn-danger" : product.deliveryStatus === 1 ? "btn btn-success" : "btn btn-warning"}>
                                                    {product.deliveryStatus === 2 ? "Canselled" : product.deliveryStatus === 1 ? "Dilevered" : "Pending"}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}



Row.propTypes = {
    row: PropTypes.shape({
        userName: PropTypes.string.isRequired,
        role: PropTypes.number.isRequired,
        phone: PropTypes.number.isRequired,
        city: PropTypes.string.isRequired,
        grId: PropTypes.string.isRequired,
        productDetails: PropTypes.arrayOf(
            PropTypes.shape({
                productName: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
                quantity: PropTypes.number.isRequired,
                total: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};


export default function DistibuterDashboard({ Auth }) {

    const [customerTab, setCustomerTab] = React.useState(0);
    const [orders, setorders] = React.useState([]);
    const [totalUSers, settotalUSers] = React.useState([]);
    const [Users, setUsers] = React.useState([]);


    async function getUsers(params) {
        try {
            const result = await apiHelper.listCustomers()
            settotalUSers(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (totalUSers.length > 0) {
            const data = totalUSers.filter(item => item.city === Auth?.city)
            setUsers(data)
        }
    }, [totalUSers])

    console.log(Auth)
    const [userData, setUserData] = React.useState([]);

    async function getUserData() {
        try {
            const result = await apiHelper.allOrderList();
            console.log(result.data.data)
            const Order = result?.data?.data?.filter(item => item?.orderedBy?.city === Auth?.city)
            setorders(Order)
            const userData = await Promise.all(
                Order?.map(async (user) => {
                    if (!user?.orderedBy?._id) return
                    const productDetails = await apiHelper.listOrderByUser(user?.orderedBy?._id);
                    return createData(
                        user?.orderedBy?.fullName,
                        user?.orderedBy?.role,
                        user?.orderedBy?.phone,
                        user?.orderedBy?.city,
                        user?.orderedBy?._id,
                         productDetails?.data?.data?.filter(item => (item.orderStatus === 1 || item.deliveryStatus === 0) && item.deliveryStatus !== 1)?.length,
                        productDetails.data.data.map((data) => ({
                            productName: data?.productId?.name,
                            price: data?.productId?.price,
                            quantity: data?.qty,
                            deliveryDue: data?.deliveryDue,
                            deliveryStatus: data?.deliveryStatus,
                            total: data.totalAmount,
                        }))
                    );
                })
            );
            const uniqueData = userData.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t?.id === value?.id
                ))
            );
            const data = uniqueData.filter(order => order?.city === Auth.city && order.role === 2)
            setUserData(data);
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        getUserData();
        getUsers()
        // eslint-disable-next-line
    }, []);


    const data = [
        { name: 'Jan', Sales: 4000, Expenses: 2400 },
        { name: 'Feb', Sales: 3000, Expenses: 1398 },
        { name: 'Mar', Sales: 2000, Expenses: 9800 },
        { name: 'Apr', Sales: 2780, Expenses: 3908 },
        { name: 'May', Sales: 1890, Expenses: 4800 },
        { name: 'Jun', Sales: 2390, Expenses: 3800 },
    ];

    const filteredOrders = {
        customer: {
            all: orders.filter(order => order?.orderedBy?.role === 2),
            pending: orders.filter(order => order.paymentStatus === 0 && order?.orderedBy?.role === 2),
            completed: orders.filter(order => order.paymentStatus === 1 && order?.orderedBy?.role === 2),
        },

    };


    const [customerData, setCustomerData] = React.useState([]);
    const [peddingOrders, setpeddingOrders] = React.useState(0);
    const [totalIncome, settotalIncome] = React.useState(0);

    React.useEffect(() => {
        if (userData.length > 0) {
            const customers = userData.filter(user => user.role === 2);
            const peddingOrders = filteredOrders?.customer?.pending?.length
            const completedOrder = filteredOrders?.customer?.completed
            let income = 0
            for (let i = 0; i < completedOrder.length; i++) {
                income += completedOrder[i].totalAmount
            }
            settotalIncome(income)
            setpeddingOrders(peddingOrders)
            setCustomerData(customers);
        }
    }, [userData]);


    return <div className="Dashboard">
        <div className="container">
            <div className="row">
                <div className="col-6 col-lg-4 my-2">
                    <div className="d-flex justify-content-center align-items-center shadow p-3">
                        <div className="content">
                            <div className="text-center">
                                <PersonIcon className='fs-2' />
                            </div>
                            <div className="count my-2">
                                <div className="h my-2">Total User</div>
                                <div className="h1 text-center">{Users?.length}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-6 col-lg-3 my-2">
                    <div className="d-flex justify-content-center align-items-center shadow p-3">
                        <div className="content">
                            <div className="text-center">
                                <ManageAccountsIcon className='fs-2' />
                            </div>
                            <div className="count my-2">
                                <div className="h my-2">Total Distributer</div>
                                <div className="h1 text-center">{Distributers?.length}</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="col-6 col-lg-4 my-2">
                    <div className="d-flex justify-content-center align-items-center shadow p-3">
                        <div className="content">
                            <div className="text-center">
                                <ShoppingCartIcon className='fs-2' />
                            </div>
                            <div className="count my-2">
                                <div className="h6 text-center">Total Order</div>
                                <div className="h1 text-center">{peddingOrders}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-lg-4 my-2">
                    <div className="d-flex justify-content-center align-items-center shadow p-3">
                        <div className="content">
                            <div className="text-center">
                                <CurrencyRupeeIcon className='fs-2' />
                            </div>
                            <div className="count my-2">
                                <div className="h6 text-center">Total Income</div>
                                <div className="h1 text-center">{totalIncome}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container my-4">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div style={{ height: 400, width: "100%" }}>
                        <ResponsiveContainer>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Sales" fill="blue" />
                                <Bar dataKey="Expenses" fill="chocolate" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="h4 fw-bold">Customers</div>
                    <div className="Dis_list">
                        <div className="Dis_Scroll border p-2 ps-3">
                            <div className="row p-2">
                                {
                                    Users && Users.map((item) => {
                                        return <div className="col-12 border p-2">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                                                <div className="text-sm-truncate text-center" style={{ maxWidth: '30%' }}>{item?.fullName}</div>
                                                <div className="text-sm-truncate text-center">{item?.phone}</div>
                                                <div className="text-sm-truncate text-center" style={{ maxWidth: '40%' }}>{item?.city}</div>
                                                {/* <div className="d-flex gap-2">
                                            <button onClick={() => {
                                                deleteHandler(item._id)
                                            }} className="btn btn-danger btn-sm">Delete</button> 
                                        </div> */}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 my-2">
                    <div className="h4 fw-bold">Order Room</div>
                    <div className="container border">
                        <div className="h5 my-3 border-bottom pb-3">Customer Orders</div>
                        <div className="row">
                            <div className="col-12">
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            value={customerTab}
                                            onChange={(event, newValue) => setCustomerTab(newValue)}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="basic tabs example"
                                        >
                                            <Tab label="All Orders" {...a11yProps(0)} />
                                            <Tab label="Pending Orders" {...a11yProps(1)} />
                                            <Tab label="Completed Orders" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>
                                    <CustomTabPanel value={customerTab} index={0}>
                                        <div className="row">
                                            <div className="col-12">
                                                <TableContainer component={Paper}>
                                                    <Table aria-label="collapsible table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell />
                                                                <TableCell className='fw-bold' align="center">Name</TableCell>
                                                                <TableCell className='fw-bold' align="center">Phone</TableCell>
                                                                <TableCell className='fw-bold' align="center">Pending Orders</TableCell>
                                                                <TableCell className='fw-bold' align="center">City</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {customerData?.map((row, index) => (
                                                                <Row key={row.grId} row={row} index={index} />
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={customerTab} index={1}>
                                        <div className="row">
                                            {
                                                filteredOrders?.customer?.pending?.map((item) => {
                                                    return <div className="col-12 col-sm-6 col-md-4 my-2">
                                                        <div className="card shadow p-4">
                                                            <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                                                            <div className="h5">{item?.orderedBy?.fullName}</div>
                                                            <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                                                            <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                                                            <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                                                            <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={customerTab} index={2}>
                                        <div className="row">
                                            {
                                                filteredOrders?.customer?.completed?.map((item) => {
                                                    return <div className="col-12 col-sm-6 col-md-4 my-2">
                                                        <div className="card shadow p-4">
                                                            <p className='text-muted fw-bold'>OrderId : {item._id}</p>
                                                            <div className="h5">{item?.orderedBy?.fullName}</div>
                                                            <div className="h6" style={{ textOverflow: "ellipsis", overflow: "hidden", textWrap: "nowrap" }}>Product : {item?.productId?.name}</div>
                                                            <p className="">qty : <strong className='text-muted'>{item?.qty}</strong></p>
                                                            <p className="">Price : <strong>₹ {item?.productId?.price}</strong></p>
                                                            <p className="h6">Total Price : <strong>₹ {item?.productId?.price * item?.qty}</strong></p>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </CustomTabPanel>
                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>




}